@use 'sass:map';
@use "variables";

$container-max-widths: (
  xs: 0px,
  sm: variables.$na-media-breakpoint-sm,
  md: variables.$na-media-breakpoint-md,
  lg: variables.$na-media-breakpoint-lg,
  xl: variables.$na-media-breakpoint-xl
) !default;

$grid-gaps-x: (
  xs: 20px,
  sm: 20px,
  md: 20px,
  lg: 30px,
  xl: 30px,
  xxl: 40px
) !default;

$grid-gaps-y: (
  xs: 30px,
  sm: 30px,
  md: 40px,
  lg: 40px,
  xl: 40px,
  xxl: 60px
) !default;

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &.align-center {
    align-items: center;
  }

  .align-self-end {
    align-self: end;
  }

  .justify-self-end {
    justify-self: end;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    @media screen and (min-width: $container-max-width) {
      $gap-x: map.get($grid-gaps-x, $breakpoint);
      $gap-y: map.get($grid-gaps-y, $breakpoint);
      gap: $gap-y $gap-x;

      @for $span from 1 through 12 {

        .span-#{$breakpoint}-#{$span} {
          display: block;
          grid-column: auto / span $span;

          @for $start from 1 through 12 {
            &-start-#{$start} {
              grid-column: $start / span $span;
            }
          }
        }

        .span-#{$breakpoint}-hidden {
          display: none;
        }
      }
    }

    &.no-gap {
      gap: 0;
    }

    &.form-gap {
      gap: 0 3rem;
    }

  }

}
