
$body-background: white;

// Colors

$na-blue: #92B9FF;
$na-dark-blue: #577DE0;
$na-light-blue: #D9E6FF;
$na-light-blue-50: #ECF3FF;
$na-disable-button: #D3D1D1;
$na-dark-grey-block: #C4C4C4;
$na-green: #D4D01A;
$na-dark-green: #9F9F00;
$na-light-green: #F2F1BA;
$na-lighter-green:#F0EFAF;
$na-active-green: #B7B740;
$na-greige: #F7F5F4;
$na-light-grey: #C4C2C1;
$na-grey: #949291;
$na-greige-50: #FBFAFA;
$na-black: #020509;
$na-dark-grey: #808284;
$na-dark-grey-50: #808284;
$na-light-grey-placeholder:#02050980;
$na-blue-bg:#DEEAFF;


// Media breakpoints

$na-media-breakpoint-sm: 375px;
$na-media-breakpoint-md: 768px;
$na-media-breakpoint-lg: 1280px;
$na-media-breakpoint-xl: 1920px;

// Fonts

$na-font-regular: 400;
$na-font-medium: 500;
$na-font-thinbold: 600;
$na-font-bold: 700;

// Header

$na-logo-height: 75px;
$na-header-margin: 25px;
$na-header-margin-sm: 0;
$na-header-height: $na-logo-height + $na-header-margin;
$na-header-height-sm: $na-logo-height + $na-header-margin-sm;

// Margins & paddings

$na-spacing: 12.5vw;
$na-spacing-secondary: 5vw;

$na-spacing-md: 12.2vw;
$na-spacing-secondary-md: 4.7vw;

$na-spacing-sm: 22.2vw;
$na-spacing-secondary-sm: 9.9vw;

$na-spacing-xl: 12.5%;
$na-spacing-xl-fraction: 0.125;
$na-spacing-secondary-xl: 5%;
$na-spacing-secondary-xl-fraction: 0.05;
