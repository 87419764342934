@use "variables";

// Base background classes

.na-base-logo-bg {
  background-repeat: no-repeat;
  background-size: 100%;
}

.na-base-logo-bg-pricing {
  background-repeat: repeat-x;
  background-origin: content-box;
  background-position-x: -10%;
  background-size: clamp(200px, 100%, 300px);

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-size: min(50%, 350px);
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    background-size: min(50%, 500px);
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    background-size: 37%;
  }
}

.na-greige-logo-bg {
  @extend .na-base-logo-bg;
  background-color: variables.$na-greige;
  background-image: url("/assets/home/NA_white_S.webp");

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-image: url("/assets/home/NA_white_M.webp");
    background-position: bottom 186px center;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    background-image: url("/assets/home/NA_white_L.webp");
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    background-position: bottom 256px center;
  }
}

.main-title-bg .na-greige-logo-bg {
  background-repeat: repeat-x;
  background-color: variables.$na-greige;
  background-image: url("/assets/home/NA_white_S.webp");
  background-size: inherit;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-image: url("/assets/home/NA_white_M.webp");
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    background-repeat: no-repeat;
    background-image: url("/assets/home/NA_white_L.webp");
    background-position: bottom 186px center;
    background-size: 100%;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    background-position: bottom 256px center;
  }
}

.na-white-logo-bg {
  @extend .na-base-logo-bg;
  background-color: white;
  // background-image: url("/assets/home/NA_greige_S.webp"); 

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-position: center calc(10% + 190px);
    background-image: url("/assets/home/NA_greige_M.webp");
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    background-image: url("/assets/home/NA_greige_L.webp");
    background-position: bottom 168px center;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    background-position: bottom 238px center;
  }
}

.na-small-screen-white-logo-bg {
  @media screen and (max-width: variables.$na-media-breakpoint-md) {
    background-color: white;
    background-image: url("/assets/home/NA_greige_S.webp");
    background-position: center calc(10% + 150px);
    background-repeat: repeat-x !important;
    background-size: inherit !important;
  }
}

.na-base-logo-bg-about {
  background-repeat: repeat-x;
  background-origin: content-box;
  background-position-x: -10%;
  background-size: clamp(200px, 0%, 300px);

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-size: clamp(200px, 100%, 300px);
    background-size: min(50%, 350px);
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    background-size: min(50%, 500px);
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    background-size: 33%;
  }
}

.na-blue-logo-bg {
  @extend .na-base-logo-bg-pricing;
  background-color: white;
  background-image: url("/assets/Pricing _ Comparison _ BG1.webp");
}

.na-gray-logo-bg {
  @extend .na-base-logo-bg-about; 
  background-color: white;
  background-image: url("/assets/aboutUs/gray.webp");

  @media screen and (min-width: variables.$na-media-breakpoint-sm) {
    background-position-x: 9vw;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-position-x: 7vw;
  }
}

.na-blue-gray-logo-bg {
  @extend .na-base-logo-bg-about;
  background-image: url("/assets/aboutUs/blue.webp");
  background-repeat: no-repeat;

  @media screen and (min-width: variables.$na-media-breakpoint-sm) {
    background-position-x: 9vw;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    background-position-x: 7vw;
  }
}

// Base paddings/margins for top section

@mixin base-padding-top($amount) {
  padding-top: calc(variables.$na-header-height + $amount);
}

.na-base-top-section {
  @include base-padding-top(143px);
  padding-bottom: 130px;
  padding-left: variables.$na-spacing;
  padding-right: variables.$na-spacing;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    @include base-padding-top(200px);
    padding-bottom: 150px;
    padding-right: 0;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    @include base-padding-top(250px);
    padding-bottom: 220px;
    padding-left: variables.$na-spacing-xl;
  }
}

.na-about-us {
  @include base-padding-top(20px);

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    @include base-padding-top(5px);
  }

}

.na-base-top-text-section {
  @include base-padding-top(max(12%, 75px));
  padding-left: variables.$na-spacing;
  padding-right: variables.$na-spacing;

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    @include base-padding-top(min(12%, 250px));
    padding-left: variables.$na-spacing-xl;
    padding-right: variables.$na-spacing-xl;
  }
}

// Base paddings/margins for general sections

.na-base-section {
  margin: variables.$na-spacing;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: variables.$na-spacing-md;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: variables.$na-spacing;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: variables.$na-spacing-xl;
  }
}

.na-base-section-indented {
  @extend .na-base-section;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding-left: variables.$na-spacing;
    padding-right: variables.$na-spacing;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding-left: variables.$na-spacing-xl;
    padding-right: variables.$na-spacing-xl;
  }
}

.na-base-section-with-bg {
  margin: variables.$na-spacing 0;
  padding: variables.$na-spacing;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: variables.$na-spacing variables.$na-spacing-secondary;
    padding: calc(variables.$na-spacing - variables.$na-spacing-secondary);
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: variables.$na-spacing-xl variables.$na-spacing-secondary-xl;
    padding: calc(variables.$na-spacing-xl - variables.$na-spacing-secondary-xl);
  }
}

.na-base-section-with-bg-small {
  @extend .na-base-section-with-bg;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    padding: variables.$na-spacing * 0.32;
  }
}

.na-base-section-secondary {
  margin: variables.$na-spacing-sm variables.$na-spacing-secondary-sm;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: variables.$na-spacing-md variables.$na-spacing-secondary-md;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: variables.$na-spacing variables.$na-spacing-secondary;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: variables.$na-spacing-xl variables.$na-spacing-secondary-xl;
  }
}

.na-base-section-padding {
  padding: 0 variables.$na-spacing-secondary-sm;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    padding: 0 variables.$na-spacing-md;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding: 0 variables.$na-spacing;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding: 0 variables.$na-media-breakpoint-xl * variables.$na-spacing-xl-fraction;
  }
}

.na-base-section-padding-secondary {
  padding: 0 variables.$na-spacing-secondary-sm;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    padding: 0 variables.$na-spacing-secondary-md;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding: 0 variables.$na-spacing-secondary;
  }
}

// Base classes for titles

.na-base-title {
  margin-bottom: 10px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-bottom: 20px;
  }
}

// Base class 3 line img section

.na-line-container {
  width: 110%;
  height: 30vw;
  position: relative;
  margin-top: 38%;
  margin-left: -5%;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin-top: 23%;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-top: 17%;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    height: variables.$na-media-breakpoint-xl * 0.3;
  }
}

// Event Css
.na-event-top-section {
  padding-top: 210px;
  padding-bottom: 130px;
  padding-left: 10.5vw;
  padding-right: 12.5vw;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    padding-top: 250px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding-left: 12.5vw;
    padding-top: 300px;
  }
}

.event-base-title {
  padding-right: 0px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding-right: 440px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding-right: 672px;
  }
}

.event-block {
  margin-top: 30px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin-top: 35px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-top: 60px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin-top: 60px;
  }
}

.event-block-heading {
  margin-bottom: 10px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin-bottom: 15px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-bottom: 25px;
  }
}

.event-margin {
  padding-left: 10.5vw;
  padding-right: 12.5vw;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding-left: 12.5vw;
    padding-right: 440px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding-right: 672px;
  }
}

// Completed Tutorials and Tutorial page Classes

.completed-base-title {
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    width: 90%;
    margin-bottom: 35px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-bottom: 40px;
    width: 100%;
  }
}

.block-1-wrapper {
  margin-bottom: 40px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 0px 96px 90px 96px;
  }

}

.block-1-img {
  // background-color: variables.$na-dark-grey-block;
  height: 210px;
  width: 100%;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    height: 432px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    height: 642px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    height: 972px;
  }
}

//Completed Article CSS
.completedArticle-top-section {  
  margin-right: 38px;
  margin-left: 37px;
  margin-top: 220px;
  margin-bottom: 40px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: 250px 152px 40px 94px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 280px 443px 72px 158px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: 400px 672px 110px 240px;
  }
}

.completedArticle-container-first {
  margin-right: 38px;
  margin-left: 37px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: 0 94px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 0 348px 0 158px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: 0 528px 0 240px;
  }
}

.completedArticle-container {
  margin-right: 38px;
  margin-left: 37px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: 0 94px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 0 538px 0 158px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: 0 816px 0 240px;
  }
}

.completedArticle-container-last {
  margin-right: 38px;
  margin-left: 37px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: 0 94px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 0 538px 0 158px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: 0 816px 0 240px;
  }
}

.completedArticle-heading {
  margin-bottom: 15px;
  letter-spacing: 0.3px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-bottom: 19px;
    letter-spacing: 0.4px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin-bottom: 30px;
  }
}

.completedArticle-content {
  margin-bottom: 35px;
  letter-spacing: 0.2px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin-bottom: 29px;
    letter-spacing: 0.4px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin-bottom: 45px;
  }
}

.completedArticle-text {
  padding-left: 20px;
  padding-top: 12px;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    padding-left: 58px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    padding-left: 95px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    padding-left: 144px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 40px;
    height: 40px;

    @media screen and (min-width: variables.$na-media-breakpoint-md) {
      left: 38px;
    }

    @media screen and (min-width: variables.$na-media-breakpoint-lg) {
      left: 77px;
    }

    @media screen and (min-width: variables.$na-media-breakpoint-xl) {
      width: 55px;
      height: 55px;
      left: 117px;
    }
  }
}

.completedArticle-quote .na-base-section-secondary {
  margin: 25px 0 30px 0;

  @media screen and (min-width: variables.$na-media-breakpoint-md) {
    margin: 49px 0 35px 0;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    margin: 42px 0 49px 0;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    margin: 65px 0 75px 0;
  }
}
