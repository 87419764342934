/* You can add global styles to this file, and also import other style files */
@use "variables";

/* jost-regular */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: variables.$na-font-regular;
  font-display: swap;
  src: url('../fonts/jost-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/jost-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/jost-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/jost-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/jost-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/jost-regular.svg#Jost') format('svg'); /* Legacy iOS */
}
/* jost-medium  */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: variables.$na-font-medium;
  font-display: swap;
  src: url('../fonts/jost-medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/jost-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/jost-medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/jost-medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/jost-medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/jost-medium.svg#Jost') format('svg'); /* Legacy iOS */
}
/* jost-semi-bold  */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: variables.$na-font-thinbold;
  font-display: swap;
  src: url('../fonts/jost-semi-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/jost-semi-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/jost-semi-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/jost-semi-bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/jost-semi-bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/jost-semi-bold.svg#Jost') format('svg'); /* Legacy iOS */
}
/* jost-bold  */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: variables.$na-font-bold;
  font-display: swap;
  src: url('../fonts/jost-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/jost-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/jost-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/jost-bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/jost-bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/jost-bold.svg#Jost') format('svg'); /* Legacy iOS */
}

html,
body {
  overflow-x: hidden;
}

.slick-slider {
  width: 88%;
  margin: auto;
}

// .carousel {
body .slick-prev {
  border: solid variables.$na-disable-button;
  border-width: 0 5px 5px 0 !important;
  pointer-events: none;
  padding: 3px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  right: 0px;
  top: 30%;
  /* right: 0; */
  left: -70px;
  display: none !important;
  transform: rotate(-225deg) translateY(55%);

  &:before {
    color: transparent;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    display: block !important;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    height: 30px;
    width: 30px;
    top: 40%;
  }
}

body .slick-next {
  border: solid black;
  border-width: 0 5px 5px 0;
  padding: 3px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  right: 0px;
  top: 30%;
  right: 3%;
  display: none !important;
  transform: rotate(-45deg) translateY(-50%);

  &:before {
    color: transparent;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    display: block !important;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    height: 30px;
    width: 30px;
    top: 40%;
  }
}

.na-slick-next-arrow {
  text-align: right;
  margin-right: 20px;
  font-size: 45px;
  color: variables.$na-dark-blue;
  cursor: pointer;

  @media screen and (min-width:variables.$na-media-breakpoint-md) {
    display: none !important;
  }
}


.next-button-container{
  top: 0;
  height: 100%;
  width: 30px;
  right: 66px;
  position: absolute; 
  @media screen and (min-width:variables.$na-media-breakpoint-xl) {
    right: 96px;
  }
}

.na-show-arrow.na-slick-next-arrow {
  @media screen and (min-width:variables.$na-media-breakpoint-md) {
    display: block !important;
    font-size: 60px;
  }
}

.na-show-arrow.na-slick-next-arrow-landing-page {
  margin-right: 25px;
  @media screen and (min-width:variables.$na-media-breakpoint-md) {
    margin-right: 93px;
    top: 0px;
  }
}

.na-slick-next-arrow-md {
  text-align: right;
  margin-right: 10px;
  font-size: 45px;
  color: variables.$na-dark-blue;
  cursor: pointer;
  top: -10vw;
  right: 20px;

  @media screen and (min-width:variables.$na-media-breakpoint-md) {
    top: -6vw;
  }

  @media screen and (min-width:variables.$na-media-breakpoint-lg) {
    top: -4vw;
  }
  @media screen and (min-width:variables.$na-media-breakpoint-xl) {
    top: -77px;
  }
}

#na-process {
  @media screen and (min-width:variables.$na-media-breakpoint-xl) {
       inset: 0px auto auto 0px !important;
       position: relative !important;
     }
}

.na-slick-next-arrow-about {
  @media screen and (min-width:variables.$na-media-breakpoint-md) {
    top: -6vw;
    display: inherit;
    text-align: right;
    margin-right: 10px;
    font-size: 45px;
    color: variables.$na-dark-blue;
    cursor: pointer;
  }

  @media screen and (min-width:variables.$na-media-breakpoint-lg) {
    display: none;
  }
}

.na-greige-bg {
  background-color: variables.$na-greige;
}

.na-dark-blue-bg {
  background-color: variables.$na-dark-blue;
}

.na-blue-bg {
  background-color: variables.$na-blue;
}

.na-black-bg {
  background-color: variables.$na-black;
}

.na-green-bg {
  background-color: variables.$na-green;
}

.na-dark-grey-bg { 
  background-color: variables.$na-dark-grey;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  background-color: transparent;
}

.na-button {
  padding: 10px 20px;
  color: variables.$na-black;
  font: 16px/16px Jost, Arial, sans-serif;
  font-weight: variables.$na-font-medium;
  border: none;
  margin: 30px auto 0;
  cursor: pointer;
  white-space: nowrap;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 20px;
    line-height: 20px;
  }
}

.na-button-primary {
  background-color: variables.$na-blue;

  &:hover,
  &:active {
    background-color: variables.$na-light-blue;
  }
}

.na-button-secondary {
  background-color: variables.$na-green;

  &:hover,
  &:active {
    background-color: variables.$na-lighter-green;
  }
}

.na-button-tertiary {
  background-color: variables.$na-black;

  &:hover,
  &:active {
    background-color: variables.$na-dark-grey;
  }
}

.na-primary-disable {
  border: 1px solid variables.$na-black;
}

a.unstyled-anchor-link {
  text-decoration: none;
  color: inherit;
  font: inherit;
}

/* Body Regular */
body,
textarea,
input {
  font: 16px/24px Jost, Arial, sans-serif;
  letter-spacing: 0.4px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 22px;
    line-height: 30px;
  }
}

p {
  margin: 0;
}

body {
  background-color: variables.$body-background;
  margin: 0;

  * {
    position: relative;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Heading 1 */
h1,
.h1 {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.7px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 34px;
    line-height: 38px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 50px;
    line-height: 56px;
  }
}

/* Heading 2 */
h2,
.h2 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.6px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 26px;
    line-height: 30px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 40px;
    line-height: 46px;
  }
}

/* Heading 3 */
h3,
.h3 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.5px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 23px;
    line-height: 26px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 36px;
    line-height: 42px;
  }
}

/* Web Mini Heading */
h4,
.h4 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: variables.$na-dark-green;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 16px;
    line-height: 22px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 24px;
    line-height: 30px;
  }
}

/* Annotation */
h5,
.h5 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-weight: variables.$na-font-medium;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 18px;
    line-height: 20px;
  }
}

/* Atomic text classes */

.font-medium {
  font-weight: variables.$na-font-medium;
}

.font-bold {
  font-weight: variables.$na-font-bold;
}

.font-thin-bold {
  font-weight: variables.$na-font-thinbold;
}

.font-normal {
  font-weight: variables.$na-font-regular;
}

.font-italic {
  font-style: italic;
}

.white-text {
  color: white;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.link-text {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 24px;
    line-height: 30px;
  }
}

/* Links */
a {
  font-size: 18px;
  line-height: 20px;
  font-weight: variables.$na-font-medium;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  color: variables.$na-dark-green;

  @media screen and (min-width: variables.$na-media-breakpoint-lg) {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (min-width: variables.$na-media-breakpoint-xl) {
    font-size: 22px;
    line-height: 30px;
  }

  &:hover {
    font-weight: variables.$na-font-bold;
  }

  &:active {
    color: variables.$na-active-green;
  }
}

a.unstyled-anchor-link {
  text-decoration: none;
  color: inherit;
  font: inherit;
}

.link {
  font-weight: variables.$na-font-medium;
  text-decoration-line: underline;
  color: variables.$na-dark-green;

  &:hover {
    font-weight: variables.$na-font-bold;
  }

  &:active {
    color: variables.$na-active-green;
  }
}

.cursor {
  cursor: pointer;
}

.overflow {
  overflow: hidden;
}

/* Atomic padding, margin css classes */

.no-margin {
  margin: 0;
}



